import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { COLORS } from "../styles/constants"
import { Link } from "gatsby"

const SupportPage = () => (
  <Layout>
    <SEO />
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '5rem 0'}}>
      <div style={{ maxWidth: '1200px', width: '100%' }}>
        <Link to={'/'}>← Return home.</Link>
        <h1>Support</h1>
        <hr />
        <p style={{ color: COLORS.accent }}>You can send feedback or ask for help by writing an email to <a href={"mailto:catalin@charlietango.co"}>catalin@charlietango.co</a>.</p>
        <Link to={'/'}>← Return home.</Link>
      </div>
    </div>
  </Layout>
)

export default SupportPage
